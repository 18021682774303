import type { GetHelpArticleRequest, GetHelpArticleResponse } from '@cash-web/protos/squareup/cashfrontend/api.pb'
import type { Region } from '@cash-web/shared-feature-region-provider'
import type { CashRequestOptions } from '@cash-web/shared-util-fetch'
import { HttpError, post } from '@cash-web/shared-util-fetch'

import type { HelpArticleSearchResult } from '../types'
import { getPublicRuntimeConfig } from './config/nextConfig'

const { cfsMeshUrl } = getPublicRuntimeConfig()

type DocumentField = {
  name: string
  value: string
}
export type SearchDocument = {
  document_id: string
  document_kind: number
  title: string
  url: string
  fields: DocumentField[]
}
export type SearchDocumentResult = {
  document: SearchDocument
}
export type SearchResponse = {
  error_message?: string
  success: boolean
  total_result_count: number
  document_results: SearchDocumentResult[]
}

type SearchPayload = {
  search_text: string
  per_page: number
  locale: { country_code: Region; language_code: string }
  page?: number
}

type StatusComponent = {
  name: string
  status: string
}

export type StatusPageResponse = {
  components: StatusComponent[]
}

const { bookshelfUrl, statusPageApiUrl } = getPublicRuntimeConfig()

export async function searchArticles(payload: SearchPayload, signal?: AbortSignal): Promise<SearchResponse> {
  const data = {
    source: 'CASH_HELP',
    search_type: 'COMPLETE',
    document_kinds: ['CASH_HELP_ARTICLE'],
    page: 1,
    ...payload,
  }

  const formData = new FormData()
  formData.append('data', JSON.stringify(data))

  const response = await fetch(bookshelfUrl, {
    method: 'POST',
    body: formData,
    signal,
  })

  if (response.ok) {
    return response.json()
  } else {
    const body = await response.text()
    throw new HttpError(body, response.status, body)
  }
}

export function toHelpResult(document_result: SearchDocumentResult): HelpArticleSearchResult {
  return {
    document_id: document_result.document.document_id,
    title: document_result.document.title,
    preview:
      document_result.document.fields.find(field => field.name == 'body')?.value?.replaceAll(/\\\\?([-&])/g, '$1') ??
      '', // Yext via bookshelf escapes dashes and ampersands
    document_kind: document_result.document.document_kind,
    url: document_result.document.url,
  }
}

export const GET_ARTICLE_URL = `${cfsMeshUrl}/2.0/cash/web/help-article`

export const getArticle = (request: GetHelpArticleRequest, options?: CashRequestOptions) =>
  post<GetHelpArticleRequest, GetHelpArticleResponse>(GET_ARTICLE_URL, request, {
    ...options,
  })

export async function getStatusPage(signal?: AbortSignal): Promise<StatusPageResponse> {
  const response = await fetch(statusPageApiUrl, {
    method: 'GET',
    signal,
  })

  if (response.ok) {
    return response.json()
  } else {
    const body = await response.text()
    throw new HttpError(body, response.status, body)
  }
}
