import styled from '@emotion/styled'
import { useTranslation } from 'next-i18next'

import Link from '../RegionalisedLink'

const ButtonContainer = styled.div`
  margin: 10px auto 30px;
  max-width: 300px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  background: ${props => props.theme.colors.secondaryButtonBackground};
  border-radius: 24px;
  color: ${props => props.theme.colors.label};
  justify-content: center;
  align-items: center;
  padding: 12px 100px;
  font-weight: 500;
`

const MoreResultsButton = ({ query }: { query: string }) => {
  const { t } = useTranslation()
  const encodedQuery = encodeURIComponent(query)
  const route = `/search#${encodedQuery}`

  return (
    <ButtonContainer>
      <StyledLink href={route}>
        <span>{t('More results', { context: 'search view more results button' })}</span>
      </StyledLink>
    </ButtonContainer>
  )
}

export default MoreResultsButton
