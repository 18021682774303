import React from 'react'

import { createPortal } from 'react-dom'

import { Modal } from '../lib/Modal'

type ModalPortalProps = { rootId?: string } & React.ComponentProps<typeof Modal>

export const ModalPortal = ({ rootId = 'modal-root', ...props }: ModalPortalProps) =>
  createPortal(<Modal {...props} />, document.getElementById(rootId) as HTMLElement)
