import { CSSProperties } from 'react'

import styled from '@emotion/styled'

export const Flex = styled.div<{
  alignItems?: CSSProperties['alignItems']
  flexDirection?: CSSProperties['flexDirection']
  flexWrap?: CSSProperties['flexWrap']
  gap?: CSSProperties['gap']
  justifyContent?: CSSProperties['justifyContent']
  flexBasis?: CSSProperties['flexBasis']
  flexShrink?: CSSProperties['flexShrink']
  flexGrow?: CSSProperties['flexGrow']
}>`
  ${({ alignItems }) => alignItems != null && `align-items: ${alignItems};`}
  ${({ flexDirection }) => flexDirection != null && `flex-direction: ${flexDirection};`}
  ${({ flexWrap }) => flexWrap != null && `flex-wrap: ${flexWrap};`}
  ${({ gap }) => gap != null && `gap: ${gap};`}
  ${({ justifyContent }) => justifyContent != null && `justify-content: ${justifyContent};`}
  ${({ flexBasis }) => flexBasis != null && `flex-basis: ${flexBasis};`}
  ${({ flexShrink }) => flexShrink != null && `flex-shrink: ${flexShrink};`}
  ${({ flexGrow }) => flexGrow != null && `flex-grow: ${flexGrow};`}
  display: flex;
`

export const FullWidthFlex = styled(Flex)`
  width: 100%;
`

export const FlexGrow = styled.div`
  flex-grow: 1;
`
