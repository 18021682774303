import { useMemo } from 'react'

import { useRouter } from 'next/router'

import { fallbackLng as defaultLocale } from '../../next-i18next.config'
import { getPublicRuntimeConfig } from '../config/nextConfig'
import { defaultRegion } from '../config/regions'

const { frontendServiceUrl } = getPublicRuntimeConfig()

/**
 * Generate a canonical URL
 * @returns A URL that can be used for link rel="canonical", with the default region and locale removed, and region preceding the locale, as that's what's used on the public frontend
 */
const useCanonicalURL = (...pathSections: string[]) => {
  const router = useRouter()

  return useMemo(
    () =>
      // On the public facing site, locale is after the region in the URL.
      // TODO cameronandrews janec Move locale to a dynamic route after region so it can be after region on both frontend and local
      [
        `${frontendServiceUrl}${router.basePath}`,
        router.query['region']?.toString()?.toLowerCase(),
        router.locale,
        ...pathSections,
      ]
        .filter(Boolean)
        .join('/')
        .replaceAll(
          // Remove default region and locale, and the 'default' locale
          new RegExp(`/(${defaultRegion}|${defaultLocale}|default)(?=/|$)`, 'gi'),
          ''
        ),
    [router.basePath, router.locale, router.query, pathSections]
  )
}

export default useCanonicalURL
