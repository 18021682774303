import styled from '@emotion/styled'

import { DocumentIcon } from '@arcade/web/icons/react'
import { CardItemExternalAnchor } from '@cash-web/support/ui-components'

import type { HelpArticleSearchResult } from '../../../types'

const StyledList = styled.ul`
  list-style-type: none;
  padding: ${({ theme }) => theme.spacing.empty};
  margin: ${({ theme }) => theme.spacing.empty};

  > li a::after {
    position: absolute;
    width: calc(100% - 55px);
    height: 1px;
    content: '';
    background-color: ${props => props.theme.colors.hairline};
    margin: auto;
    text-align: center;
    left: 0;
    right: 0;
  }

  > li a:hover::after {
    display: none;
  }

  > li:last-child a::after {
    display: none;
  }
`

const ResultCards = ({ results, query }: { results: HelpArticleSearchResult[]; query: string }) => {
  return (
    <StyledList>
      {results.map(result => (
        <li key={result.document_id}>
          <CardItemExternalAnchor
            icon={<DocumentIcon />}
            title={result.title}
            subtitle={result.preview}
            to={`${result.url}?searchText=${encodeURIComponent(query)}`}
          />
        </li>
      ))}
    </StyledList>
  )
}

export default ResultCards
