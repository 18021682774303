import { useEffect } from 'react'

import Bugsnag from '@bugsnag/js'
import styled from '@emotion/styled'
import { useTranslation } from 'next-i18next'
import { useQuery } from 'react-query'

import { spacing, Stack, StatusBanner } from '@cash-design-system/react'
import { useLogCashWebCdfEvent } from '@cash-web/shared-feature-analytics'
import {
  CustomerSupportAccessOpenIncidentAlert,
  CustomerSupportAccessViewIncidentAlerts,
} from '@squareup/customer-data-events-web'

import { getStatusPage } from '../../api'
import { getPublicRuntimeConfig } from '../../config/nextConfig'

const { statusPageUrl } = getPublicRuntimeConfig()

const BadgeContainer = styled.div`
  margin-bottom: ${spacing.xxl};
`

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const StatusBadge = () => {
  const { t } = useTranslation()
  const logCdfEvent = useLogCashWebCdfEvent()
  const { data } = useQuery(
    ['statusPage'],
    ({ signal }) =>
      getStatusPage(signal).then(data => ({
        errorComponents: data.components.filter(component => component.status !== 'operational'),
      })),
    {
      onError: error => {
        if (error instanceof Error) {
          Bugsnag.notify(error)
        }
      },
    }
  )

  useEffect(() => {
    if (data?.errorComponents.length) {
      logCdfEvent(
        CustomerSupportAccessViewIncidentAlerts({
          totalCount: data?.errorComponents.length,
        })
      )
    }
  }, [data?.errorComponents.length, logCdfEvent])

  const trackOpenIncidentEvent = () => {
    logCdfEvent(CustomerSupportAccessOpenIncidentAlert({}))
  }

  return data?.errorComponents.length ? (
    <BadgeContainer>
      <StatusBanner
        variant="error"
        message={
          <Stack space="10px" as="span">
            <strong>
              {t('Service Outage', {
                context: 'Title for the status banner that is shown when we have at least one service outage',
              })}
            </strong>
            <StyledLink href={statusPageUrl} target="_blank" rel="noopener" onClick={trackOpenIncidentEvent}>
              {t('Click here to check status page for details', {
                context: 'Link text to check the status page for Cash when at least one component is broken',
              })}
            </StyledLink>
          </Stack>
        }
      />
    </BadgeContainer>
  ) : (
    <></>
  )
}

export default StatusBadge
