import styled from '@emotion/styled'

import { Content } from '@cash-design-system/react'
// todo: fix once breakpoints available from arcade
// eslint-disable-next-line no-restricted-imports
import { BreakpointSmall } from '@cash-design-system/tokens'

const HelpContent = styled(Content)`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin: 0 auto;

  @media (min-width: ${BreakpointSmall}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

export default HelpContent
