import React, { forwardRef, useState } from 'react'

import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import Icon from './../Icon/Icon'
import { BaseSearchInput } from './BaseSearchInput'

export type SearchInputProps = React.ComponentProps<typeof BaseSearchInput> & {
  CustomInput?: React.ComponentType<React.ComponentProps<typeof BaseSearchInput>>
  customInputProps?: Record<string, unknown>
}

const InputContainer = styled.div`
  position: relative;
  display: grid;

  input {
    padding-left: ${({ theme }) => theme.spacing.xxl};
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }

  // hide the x on search bar
  [type='search']::-webkit-search-decoration,
  [type='search']::-webkit-search-results-button,
  [type='search']::-webkit-search-results-decoration {
    display: none;
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.5rem;
  display: grid;
  place-items: center;
  pointer-events: none;
`

const IconTarget = styled.div<{
  iconColor: string
}>`
  width: 2.75rem;
  height: 2.75rem;
  display: grid;
  place-items: center;
  background: transparent;
  border: none;
  padding: ${({ theme }) => theme.spacing.empty};
  color: ${props => props.theme.colors.tertiaryIcon};
  outline-style: none;
`

/**
 * SearchInput has not been migrated to an Arcade component.
 *
 * SearchInput will be a separate component from Input in the Arcade system.
 * See: https://www.figma.com/design/fu8vNWffYkGUMyznuQyzhc/02-Arcade---Mobile?node-id=6524-13816&t=RUv18V8Z75RS6snx-0
 *
 * Note: BaseSearchInput and MooncakeSearchInput are copied from cds-react Input and MooncakeInput components.
 */
const DefaultInput = styled(BaseSearchInput)`
  min-height: 50px;
  border-radius: 2.5rem;
`

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ CustomInput = DefaultInput, customInputProps, ...props }, ref) => {
    const [isInputFocused, setInputFocused] = useState(false)
    const theme = useTheme()

    const iconColor = (() => {
      if (props.isInvalid) {
        return theme.colors.error
      }
      if (props.disabled) {
        return theme.colors.disabledIcon
      }
      if (isInputFocused) {
        return theme.colors.primary
      }
      return theme.colors.label
    })()

    const inputProps = { ...customInputProps, ...props }

    return (
      <InputContainer role="search">
        <IconContainer>
          <IconTarget iconColor={iconColor}>
            <Icon name="search" fontSize="1.5rem" />
          </IconTarget>
        </IconContainer>
        <CustomInput
          role="searchbox"
          {...inputProps}
          onFocus={(event: React.FocusEvent<HTMLInputElement, Element>) => {
            props.onFocus?.(event)
            setInputFocused(true)
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement, Element>) => {
            props.onBlur?.(event)
            setInputFocused(false)
          }}
          ref={ref}
        />
      </InputContainer>
    )
  }
)

export default SearchInput
