import React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { getColor, Theme } from '../../theme/colors'
import { BaseSearchInputProps } from './BaseSearchInput'

const error = (theme: Theme) => {
  return css`
    &,
    &:focus {
      caret-color: ${theme.colors.error};
      border-color: ${theme.colors.error};
    }
  `
}

const focus = (theme: Theme) => {
  return css`
    border: 2px solid ${theme.colors.inputFocusBorder};
    background: ${theme.colors.background};
    outline: none;
  `
}

const BaseInput = styled.input<BaseSearchInputProps>`
  appearance: none;
  min-height: 64px;
  display: block;
  align-items: center;
  padding: 0 1rem;
  font-family: inherit;
  border-radius: 1rem;
  letter-spacing: 0.01em;
  box-sizing: border-box;
  ${props => props.theme.typography.inputText};
  caret-color: ${getColor('inputFocusBorder')};
  border: 2px solid ${getColor('formFieldBackground')};
  background-color: ${getColor('formFieldBackground')};
  width: 100%;

  ${props => props.isVisuallyFocused && focus(props.theme)}
  :focus {
    ${props => focus(props.theme)}
  }

  &::placeholder {
    color: ${getColor('placeholderLabel')};
  }

  color: ${getColor('label')};

  &:disabled {
    color: ${getColor('disabledLabel')};
    background: ${getColor('formFieldBackgroundDisabled')};
    border-color: ${getColor('formFieldBackgroundDisabled')};
  }

  ${({ isInvalid, theme }) => isInvalid && error(theme)}

  &.error {
    ${props => error(props.theme)}l.
  }
  ${props =>
    props.isHighlighted &&
    css`
      background-color: ${props.theme.colors.bannerBackgroundSuccess};
      border-color: ${props.theme.colors.inputFocusBorder};
    `}

  /* Override default browser styling for auto filled inputs */
  &:-webkit-autofill {
    -webkit-text-fill-color: ${getColor('label')};
    box-shadow: 0 0 0 1000px ${getColor('formFieldBackground')} inset;
    background: ${getColor('formFieldBackground')};

    &:focus {
      ${props => focus(props.theme)}
      box-shadow: 0 0 0 1000px ${getColor('background')} inset;
    }

    &:disabled {
      -webkit-text-fill-color: ${getColor('disabledLabel')};
      box-shadow: 0 0 0 1000px ${getColor('formFieldBackgroundDisabled')} inset;
      background: ${getColor('formFieldBackgroundDisabled')};
    }

    &::first-line {
      ${props => props.theme.typography.inputText};
    }
  }
`

const MooncakeSearchInput = React.forwardRef<HTMLInputElement, BaseSearchInputProps>((props, ref) => (
  <BaseInput aria-invalid={props.isInvalid ? true : undefined} ref={ref} {...props} />
))

export default MooncakeSearchInput
