import Bugsnag from '@bugsnag/js'
import styled from '@emotion/styled'
import { useTranslation } from 'next-i18next'

import { CardItem } from '@cash-web/support/ui-components'

import type { HelpArticleSearchResult } from '../../../types'
import MoreResultsButton from './MoreResultsButton'
import ResultCards from './ResultCards'

const ResultsContainer = styled.div`
  position: relative;
`

const SearchResults = ({
  results,
  query,
  showMoreResults = false,
  isErrored = false,
  errorMessage = '',
}: {
  results: HelpArticleSearchResult[]
  query: string
  showMoreResults?: boolean
  isErrored?: boolean
  errorMessage?: string
}) => {
  const { t } = useTranslation()

  if (isErrored) {
    const message = errorMessage.length === 0 ? 'unknown error' : errorMessage
    Bugsnag.notify(new Error(`Help: search error "${message}", with query length "${query.length}"`))
    return (
      <CardItem
        icon={<></>}
        title={t('Error with your search query', { context: 'search error title' })}
        subtitle={t('We had trouble with your search. Please try again later.', { context: 'search error subtitle' })}
      />
    )
  }
  return results.length ? (
    <>
      <ResultsContainer>{<ResultCards results={results} query={query} />}</ResultsContainer>
      {showMoreResults ? <MoreResultsButton query={query} /> : undefined}
    </>
  ) : (
    <CardItem
      icon={<></>}
      title={t('No results found', { context: 'search no results title' })}
      subtitle={t('Please try using other search terms or try more general keywords. Or you can try contacting us.', {
        context: 'search no results subtitle',
      })}
    />
  )
}

export default SearchResults
