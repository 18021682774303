import { useCallback, useEffect, useRef, useState } from 'react'

import Bugsnag from '@bugsnag/js'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from 'next-i18next'
import type { ChangeEventHandler } from 'react'
import { useQuery } from 'react-query'

import { Header, LoadingIcon, SearchInput, spacing, useDebounce } from '@cash-design-system/react'
import { useLogCashWebCdfEvent } from '@cash-web/shared-feature-analytics'
import { Modal } from '@cash-web/shared-feature-modal'
import { useCountryFromRegionProvider } from '@cash-web/shared-feature-region-provider'
import { CustomerSupportSearchEnterText, CustomerSupportSearchStart } from '@squareup/customer-data-events-web'

import { fallbackLng as defaultLocale } from '../../../next-i18next.config'
import type { HelpArticleSearchResult } from '../../../types'
import { searchArticles, toHelpResult } from '../../api'
import type { Region } from '../../config/regions'
import { defaultRegion, supportedRegions } from '../../config/regions'
import { DEBOUNCED_TIME, MAX_DISPLAYED_RESULTS, MAX_DISPLAYED_RESULTS_SEARCH_PAGE } from './constants'
import SearchResults from './SearchResults'

type SearchInputProps = {
  invert?: boolean
}

type SearchProps = {
  initialHash?: string
  showMoreResults: boolean
  invert?: boolean
}

const ContainerStyles = css`
  margin-top: ${spacing.smallGap};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ResultsContainer = styled.div`
  ${ContainerStyles}
  background: ${props => props.theme.colors.background};
  margin: 20px -28px 0;
`

const TopArticleHeader = styled(Header)`
  margin-top: ${spacing.xl};
`

const StyledSearchInput = styled(SearchInput)<SearchInputProps>`
  background: ${props => (props.invert ? props.theme.colors.searchBackground : props.theme.colors.background)};

  ::placeholder {
    color: ${props => (props.invert ? props.theme.colors.tertiaryLabel : 'default')};
  }
`

const LoadingContainer = styled.div`
  ${ContainerStyles}
  background: ${props => props.theme.colors.background};
  align-items: center;
  padding: ${spacing.l};
  gap: ${spacing.l};
`

const ModalContent = styled.div`
  margin-top: ${spacing.s};
`

const Search = ({ showMoreResults, initialHash, invert = false }: SearchProps) => {
  const { t } = useTranslation()
  const logCdfEvent = useLogCashWebCdfEvent()
  const region = useCountryFromRegionProvider()?.toLowerCase() as Lowercase<Region> | undefined
  const { region: regionToRequest, defaultLocale: localeToRequest } = (region && supportedRegions[region]) ?? {
    region: defaultRegion,
    defaultLocale: supportedRegions[defaultRegion]?.defaultLocale ?? defaultLocale,
  }
  const [captureIntent, setCaptureIntent] = useState(false)
  const [readOnlyInputValue, setReadOnlyInputValue] = useState('')
  const [showTopArticles, setShowTopArticles] = useState(true)
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce(query.trim(), DEBOUNCED_TIME)
  const [openModal, setOpenSearchModal] = useState(false)

  // TODO: replace this with an api call. Go with the simplist approach first
  const topArticles: HelpArticleSearchResult[] = [
    {
      document_id: 'cash-help-article-3130',
      title: 'Access Old Account',
      preview:
        'If you are having trouble accessing an old account, it may be associated with another phone or email address.',
      document_kind: 6,
      url: 'http://cash.app/help/3130',
    },
    {
      document_id: 'cash-help-article-3074',
      title: "Withdrawal wasn't Instant",
      preview: "If your debit card doesn't accept Instant Deposit, you will be refunded any Instant Deposit fees",
      document_kind: 6,
      url: 'https://cash.app/help/us/en-us/3074-cash-out-not-instant',
    },
    {
      document_id: 'cash-help-article-3123',
      title: 'What is a $Cashtag?',
      preview: 'A $Cashtag is a unique identifier for individuals and businesses using Cash App',
      document_kind: 6,
      url: 'https://cash.app/help/us/en-us/3123-cashtags',
    },
    {
      document_id: 'cash-help-article-3051',
      title: 'Canceling a Payment',
      preview: "Cash App payments are instant and usually can't be canceled.",
      document_kind: 6,
      url: 'https://cash.app/help/us/en-us/3051-cancel-a-payment',
    },
  ]

  useEffect(() => {
    if (initialHash && initialHash.length > 0) {
      setQuery(initialHash)
      setReadOnlyInputValue(initialHash)
    }
  }, [initialHash])

  const { data, isLoading, isError } = useQuery(
    ['search', debouncedQuery, regionToRequest, localeToRequest],
    ({ signal }) => {
      const payload = {
        search_text: debouncedQuery,
        per_page: showMoreResults ? MAX_DISPLAYED_RESULTS : MAX_DISPLAYED_RESULTS_SEARCH_PAGE,
        locale: { country_code: regionToRequest.toUpperCase() as Uppercase<Region>, language_code: localeToRequest },
        page: 1,
      }
      // hide the top articles only after we make at least one query call
      setShowTopArticles(false)
      return searchArticles(payload, signal).then(data => ({
        results: data.document_results?.map(toHelpResult),
        total_result_count: data.total_result_count,
        success: data.success,
        error_message: data.error_message,
      }))
    },
    {
      retry: false,
      enabled: debouncedQuery?.length > 1,
      onError: error => {
        if (error instanceof Error) {
          Bugsnag.notify(error)
        }
      },
    }
  )

  const searchInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus()
    }
  }, [openModal])

  const onDisplayChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setOpenSearchModal(true)
      setCaptureIntent(false)
      logCdfEvent(CustomerSupportSearchEnterText({}))
      setQuery(event.target.value)
    },
    [logCdfEvent]
  )

  const onFocusHandler = () => {
    setCaptureIntent(true)
    logCdfEvent(CustomerSupportSearchStart({}))
  }

  const onQueryChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (captureIntent) {
        setCaptureIntent(false)
        logCdfEvent(CustomerSupportSearchEnterText({}))
      }
      if (event.target.value.length < 2) {
        setShowTopArticles(true)
      }
      setQuery(event.target.value)
    },
    [captureIntent, logCdfEvent]
  )

  const onSearchModalClose = () => {
    setReadOnlyInputValue(query)
    setOpenSearchModal(false)
  }

  const searchPlaceholder = t('Example: PIN reset', {
    context: 'search placeholder',
    nsSeparator: false,
  })
  const shouldShowMoreResults = showMoreResults && (data?.total_result_count ?? 0) > MAX_DISPLAYED_RESULTS
  const hasErrors = isError || !data?.success
  return (
    <>
      <StyledSearchInput
        invert={invert}
        onFocus={onFocusHandler}
        placeholder={searchPlaceholder}
        type="search"
        onChange={onDisplayChangeHandler}
        value={readOnlyInputValue}
      />
      {openModal && (
        <Modal fixed onClose={onSearchModalClose} width="45rem">
          <ModalContent>
            <StyledSearchInput
              data-testid="modal-input"
              invert
              ref={searchInput}
              placeholder={searchPlaceholder}
              type="search"
              value={query}
              onChange={onQueryChangeHandler}
            />
            {isLoading && (
              <LoadingContainer>
                <div data-testid="loading-icon">
                  <LoadingIcon />
                </div>
              </LoadingContainer>
            )}
            {(isError || data) && (
              <ResultsContainer>
                <SearchResults
                  results={data?.results ?? []}
                  query={query}
                  showMoreResults={shouldShowMoreResults}
                  isErrored={hasErrors}
                  errorMessage={data?.error_message}
                />
              </ResultsContainer>
            )}
            {showTopArticles && !data && (
              <>
                <TopArticleHeader variant="sectionTitle">
                  {t('Top articles', { context: 'Top recommended articles when performing a search' })}
                </TopArticleHeader>
                <ResultsContainer>
                  <SearchResults
                    results={topArticles}
                    query={query}
                    showMoreResults={shouldShowMoreResults}
                    isErrored={false}
                  />
                </ResultsContainer>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default Search
