import React from 'react'

import MooncakeSearchInput from './MooncakeSearchInput'

export interface BaseSearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isInvalid?: boolean
  /**
   * @Deprecated - no current usages
   */
  isHighlighted?: boolean
  isVisuallyFocused?: boolean
  label?: string
}

export const BaseSearchInput = React.forwardRef<HTMLInputElement, BaseSearchInputProps>((props, ref) => {
  return <MooncakeSearchInput aria-invalid={props.isInvalid ? true : undefined} ref={ref} {...props} />
})
